<template>
    <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="activity-item">
                <img src="@/assets/images/activity/1.jpg">
                <div class="activity-mask"></div>
                <div class="activity-date">2020 年 06 月 22 日 - 2020 年 06 月 30 日</div>
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="activity-item">
                <img src="@/assets/images/activity/2.jpg">
                <div class="activity-mask"></div>
                <div class="activity-date">2020 年 06 月 22 日 - 2020 年 06 月 30 日</div>
            </div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="activity-item">
                   <img src="@/assets/images/activity/3.jpg">
                <div class="activity-mask"></div>
                <div class="activity-date">2020 年 06 月 22 日 - 2020 年 06 月 30 日</div>
            </div>
        </el-col>

        <el-col :span="24">
            <el-alert
                title="更多活动敬请期待"
                type="warning">
            </el-alert>
        </el-col>
    </el-row>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.activity-item{
    box-sizing: border-box;
    margin: 0 0 20px 0;
    min-width: 0;
    display: flex;
    position: relative;
    border-radius: 8px;
    height: 100%;
    .activity-mask{
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: inherit;
    }
    .activity-date{
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        bottom: 25px;
        color: rgb(255, 255, 255);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 18px;
        line-height: 18px;
        white-space: nowrap;
    }
}
.activity-item img{
    border-radius: inherit;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>

